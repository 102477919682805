import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

import Texts from '@/components/Texts';
import InsideLinks from '@/components/InsideLinks';

import useStyle, { animationProps } from './styles';

const Person = ({ imageData, text, title, links }) => {
  const classes = useStyle();
  const image = getImage(imageData);

  return (
    <motion.div className={classes.animate} {...animationProps}>
      <p className={classes.desktopTitle}>{title}</p>
      <div className={classes.contentContainer}>
        <div className={classes.textContainer}>
          <p className={classes.mobileTitle}>{title}</p>
          <Texts content={text} />
        </div>
        <div className={classes.imageContainer}>{image && <GatsbyImage image={image} alt={imageData.originalName} />}</div>
      </div>
      <p className={classes.pageTitle}>Stiftungsrat</p>
      <InsideLinks links={links} />
    </motion.div>
  );
};

Person.defaultProps = { imageData: null };
Person.propTypes = {
  links: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)).isRequired,
  imageData: PropTypes.shape({
    originalName: PropTypes.string.isRequired,
    gatsbyImageData: PropTypes.shape({}).isRequired,
  }),
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default Person;
